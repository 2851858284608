<template>
  <div v-if="loading" class="transfer-class" v-loading="loading" element-loading-text="数据访问中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
  <div v-if="!loading" class="transfer-class" style="display: flex;justify-content: center;align-items: center;">
    <el-image
      style="width: 50%; height: 50vh"
      :src="src"
      fit="contain"></el-image>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBG } from '@/api/forward'
export default {
  data () {
    return {
      loading: true,
      src: require('@/assets/error-404.svg')
    }
  },
  mounted () {
    const companyId = this.$route.params.id
    this.initCompanyDetail(companyId)
  },
  methods: {
    // 获取企业基本工商信息
    initCompanyDetail (companyId) {
      const data = {
        dateBackgroundUrl: '/companyInfo/companyDetail',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          this.companyNameClick(companyId)
        } else {
          ElMessage.error({
            message: '非法的请求',
            duration: 5000
          })
          this.loading = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 点击了 公司名 跳转到公司详情
    companyNameClick (companyId) {
      const vm = this
      vm.$store.dispatch('setBusId', companyId)
      vm.$router.push({ name: 'enterprise-details' })
    }
  }
}
</script>

<style scoped="scoped">
.transfer-class {
  height: 100vh;
  width: 100%;
}
</style>
